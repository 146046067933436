<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div>
        <router-view/>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<script>


export default {
  name: 'Standard',
  props: {
  },
  components: {},
  data() {
    return {
    };
  }
};
</script>

<style scoped lang="scss">
</style>
